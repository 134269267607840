// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import * as Highcharts from "highcharts"
import * as bootstrap from "bootstrap"
import { Sortable } from "sortablejs"
import "./one-displayed"
import "./like-button"
import "./group"
import "./sortable"
import "jquery"
import "trix"
import "@rails/actiontext"

window.Highcharts = Highcharts
window.Sortable = Sortable
