document.addEventListener("turbo:load", function () {
  const elements = document.querySelectorAll('.one-displayed-open, .one-displayed-closed')
  elements.forEach(e => {
    if (e.querySelectorAll('li').length > 1) {
      const small = document.createElement('small')
      const link = Object.assign(document.createElement('a'), {
        className: 'showhide',
        href: '#',
        innerHTML: 'Show more'
      })
      small.appendChild(link)
      e.appendChild(small)
    }
  })

  const buttons = document.querySelectorAll('.showhide')
  buttons.forEach(b => {
    b.onclick = (e) => {
      if (b.closest('ul.one-displayed-closed')) {
        const element = b.closest('ul.one-displayed-closed')
        element.classList.remove('one-displayed-closed')
        element.classList.add('one-displayed-open')
      }
      else if (b.closest('ul.one-displayed-open')) {
        const element = b.closest('ul.one-displayed-open')
        element.classList.remove('one-displayed-open')
        element.classList.add('one-displayed-closed')
      }
      return false
    }
  })
})
