document.addEventListener("turbo:frame-render", function() {

  const buttons = document.querySelectorAll('.like_button a')
  buttons.forEach(b => {
    b.onclick = () => {
      if (b.classList.contains('liked')) {
        b.classList.remove('liked')
      }
      else {
        b.classList.add('liked')
      }
    }
  })
});