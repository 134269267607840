document.addEventListener("turbo:load", function () {
    const putOrder = (url, ids, parent_id) => {
        const token = document.querySelector('meta[name="csrf-token"]').content;
        void fetch(url, {
            method: 'PUT',
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ids, parent_id})
        } );
    }

    const sortableLists = document.querySelectorAll('.sortable');
    sortableLists.forEach(sortableList => {
        Sortable.create(sortableList, {
            handle: ".handle",
            group: true,
            animation: 80,
            direction: 'vertical',
            onEnd: (droppedItem) => {
                const list = droppedItem.item.parentElement
                const items = Array.from(list.querySelectorAll(':scope > .sortable_item'))
                const parent = list.closest('.sortable_item')
                putOrder(list.dataset.url, items.map(i => i.dataset.id), parent?.dataset.id);
            }
        });
    } )
})