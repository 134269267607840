activateGroupFormButton = () => {
    const button = document.querySelector('#add_selected_button');
    const checkedCount = document.querySelectorAll('#participant_list .participant input[type="checkbox"]:checked').length;
    const countField = document.querySelector('#participants_selected_count');
    if (countField) {
        countField.textContent = checkedCount.toString();
        button.disabled = checkedCount === 0
    }
 }

document.addEventListener("turbo:load", function() {
    const checkboxes = document.querySelectorAll('#participant_list .participant input[type="checkbox"]')
    checkboxes.forEach(b => {
        b.onclick = (e) => {
            e.stopPropagation()
            activateGroupFormButton()
        }
    })
    activateGroupFormButton()

    const selectAllCheckbox = document.querySelector('#participant_list #select_all')
    if (selectAllCheckbox) {
        selectAllCheckbox.onclick = (e) => {
            e.stopPropagation()
            checkboxes.forEach(checkbox => {
                checkbox.checked = selectAllCheckbox.checked
            })
            activateGroupFormButton()
        }
    }
});
